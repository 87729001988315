import $ from "jquery"

export default class TablesHelper {

	row_click_redirect(event, url) {
		let sender_element = event.target
		if(sender_element) {
			let sender_tag = sender_element.tagName
			if(sender_tag && sender_tag.toUpperCase() === 'TD') { Turbolinks.visit(url) }
		}
		return true
	}
}

$(document).on('turbolinks:load', function() {
		$("th.sortable, .sortable .pagination").on("click", "a", function() {
				Turbolinks.visit(this.href)
				return false
			}
		)
		if ($("th.sortable i[name='sort-icon']").length === 0) {
			$("th.sortable a").append("&nbsp;<i name='sort-icon' class='fal fa-sort'></i>") 
		}
		$("th.sortable a.current [name='sort-icon']").remove()
		$("th.sortable a.current.asc").append("&nbsp;<i name='sort-icon' class='fal fa-sort-down'></i>")
		$("th.sortable a.current.desc").append("&nbsp;<i name='sort-icon' class='fal fa-sort-up'></i>")
	}
)

