import Turbolinks from 'turbolinks';

document.addEventListener('turbolinks:load', function(event) {
	for (let form of document.querySelectorAll('form[method=get]:not([data-remote=true])')) {
		form.addEventListener('submit', function (event) {
			event.preventDefault()
			var form_data = new FormData(event.target)
			var url_params = ''
			var first = false
			for (var [key, value] of form_data.entries()) {
				if(first) { url_params += `${key}=${value}` }
				else { url_params += `&${key}=${value}` }
			}
			var url = event.target.action + '?' + url_params
			console.log(url)
			Turbolinks.visit(url)
		})
	}
})
