export default class Spin {
	spin_button(element, message='Loading...') {
		element.classList.add('disabled')
		element.disabled = true
		element.innerHTML = `
<div class='fa fal fa-spinner fa-spin fa-fw'></div>
${message}
`
	}
}

window.spin = new Spin()
