// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
//ActiveStorage.start()

import "controllers"

import $ from "jquery"
window.$ = $
window.jQuery = window.$

import Popper from 'popper.js'
window.Popper = Popper

import "chartkick"
import "chart.js"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui/ui/widgets/slider"
import AOS from "aos/dist/aos.js"
window.AOS = AOS

// Custom SCSS //
require("../scss/application.scss")

import "bootstrap"

import Nav from '../app/nav.js'
window.nav = new Nav()

import { omnisearch_change, show_omnisearch_results } from '../app/omnisearch.js'
window.omnisearch_change = omnisearch_change
window.show_omnisearch_results = show_omnisearch_results

import TablesHelper from '../app/tables_helper.js'
window.tables = new TablesHelper()

import Filer from '../app/filer.js'
import '../app/spin.js'
import '../app/turboform.js'

import { filter_list, reset_filter_list } from '../app/filter_list.js'
window.filter_list = filter_list
window.reset_filter_list = reset_filter_list

require("../app/dropdown_helper.js")
require("../app/tooltips.js")
require("../app/stimulus_reflex.js")

