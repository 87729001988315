export function filter_list(elements, search_input) { 
	var search_text = search_input.value
	if (!search_text || search_text.length == 0) { reset_filter_list(elements) }

	const is_match = (element, search_text) => (element.innerText.toUpperCase().indexOf(search_text.toUpperCase()) >= 0)
	elements.forEach( (element) => {
		if (is_match(element, search_text)) {
			element.classList.remove('d-none')
		} else {
			element.classList.add('d-none')
		}
	})
}

export function reset_filter_list(elements) {
	elements.forEach( (element) => element.classList.remove('d-none') )
}
